#root {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  width: 100% !important;
  background-color: #fbfaf5;
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.marquee-content {
  display: flex;
  white-space: nowrap;
  width: 270%;
  height: 600px;
  animation: marquee 16s linear infinite;
}

.marquee-content img {
  height: 100% !important;
  width: 100%;
  /* object-fit: cover; */
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.slick-dots li button {
  width: 13px !important;
  height: 13px !important;
  padding: 0 !important;
}

.slick-dots {
  button {
    border: 1px solid black !important;
    border-radius: 50%;
  }
}

.slick-dots li button:before {
  height: 13px !important;
  width: 13px !important;
  opacity: 0 !important;
  border-radius: 50% !important;
}

.slick-dots li.slick-active button:before {
  background-color: #f8a065 !important;
  opacity: 1 !important;
  color: #f8a065 !important;
  border: 1px solid black;
}

.parallax {
  background-image: url(../../public/image/about/p2-parallax-img.jpg);
  background-attachment: fixed;
  background-position: center;
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fbfaf5;
  border-bottom: 2px solid #fff;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 680px) {
  .parallax {
    height: 400px !important;
  }
}

@media screen and (max-width: 1024px) {
  .marquee-content {
    height: 100%;
  }
}

@media screen and (max-width: 640px) {
  .marquee-content {
    width: 400%;
  }
}

.slick-slide img {
  margin: auto;
}


/* .text-container {
  width: 100%;
  height: 100%;
}

.scroll-text {
  display: inline-block;
  white-space: nowrap;
}

button:hover .text-container {
  animation: scroll-horizontal 2s linear infinite;
}

@keyframes scroll-horizontal {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
} */


/* .scroll-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
}

.scroll-text-wrapper {
  display: flex;
  align-items: center;
  will-change: transform;
  animation: scroll 10s linear infinite;
}

.scroll-text {
  display: inline-block;
  padding-right: 2rem;
}

@keyframes scroll {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
} */

.scroll-container:hover .scroll-text-wrapper {
  animation-play-state: running;
}


.qodef-menu-item-brush {
  -webkit-mask-position: 0 0;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
  transition-duration: .9s, .2s;
  z-index: -1;
  --qode-brush-color: #f8a065;
  -webkit-mask-image: radial-gradient(circle at 66px 26px, transparent 27%, #000 90%);
  -webkit-mask-repeat: no-repeat;
  /* -webkit-mask-position: -75px 0; */
  fill: #f8a065;
}

.hover_img1,
.hover_img2,
.hover_img3,
.hover_img4,
.hover_img5,
.hover_img6 {
  position: absolute;
  display: none;
  left: -15px;
  bottom: -15px;
}

.nav_link1 a,
.nav_link2 a,
.nav_link3 a,
.nav_link4 a,
.nav_link5 a,
.nav_link6 a {
  z-index: 10;
  position: relative;
}

.nav_link1,
.nav_link2,
.nav_link3,
.nav_link4,
.nav_link5,
.nav_link6 {
  position: relative;
  z-index: 10;

}

.nav_link1:hover .hover_img1,
.nav_link2:hover .hover_img2,
.nav_link3:hover .hover_img3,
.nav_link4:hover .hover_img4,
.nav_link5:hover .hover_img5,
.nav_link6:hover .hover_img6 {
  display: block !important;
}

/* .sliding-text-wrapper {
  display: flex;
  animation: slide 1s linear infinite;
  width: 300%;
}

.sliding-text {
  white-space: nowrap;
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-33.33%);
  }
} */
.sliding-text-wrapper {
  display: flex;
  width: 300%;
  transform: translateX(0%);
  transition: transform 0.3s ease-in-out;
}

button:hover .sliding-text-wrapper {
  animation: slide 1s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-33.33%);
  }
}

.port_active {
  position: absolute;
  top: 0;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  border: 0 !important;
  --tw-ring-color: rgb(0 0 0 / var(--tw-border-opacity))
}



.dot-grid {
  display: grid;
  grid-template-columns: repeat(2, 10px);
  grid-template-rows: repeat(2, 10px);
  gap: 6px;
  position: relative;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: black;
  position: relative;
  transition: transform 0.3s ease;
}

.empty-dot {
  border: 2px solid black;
  background-color: transparent;
}

.move-to-second {
  transform: translate(16px, 0);
}

.move-to-first {
  transform: translate(0px, -16px);
}

.move-to-third {
  transform: translate(-16px, 16px) !important;
}

.social_icon {
  padding: 5px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid transparent;
  cursor: pointer;
}

.social_icon:hover {
  border: 1px solid black;
  background-color: #f8a065;
}

.rotate-btn {
  transition: transform 0.5s ease-in-out;
}

.rotate-btn:hover {
  transform: rotate(360deg);
}

.custom_tool_tip:before {
  content: '';
  position: absolute;
  right: 10px;
  bottom: -9px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 1px 0 20px;
  border-color: #fff transparent transparent transparent;
  transform: rotate(0);
  z-index: 100;
}

.custom_tool_tip:after {
  content: '';
  position: absolute;
  right: 10px;
  bottom: -10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 0 20px;
  border-color: #000 transparent transparent transparent;
  transform: rotate(0);
}


.client_2 .slick-slide {
  position: relative;
  left: -120px;
}

/* @media screen and (max-width: 1600px) {
}

@media screen and (max-width: 640px) {
  .client_2 .slick-slide {
    width: 210px !important;
    position: relative;
    left: -75px;
  }
} */