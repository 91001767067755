@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'flowbite';

body {
  font-family: 'Archivo', sans-serif;
  /* overflow-x: hidden; */
}

.custom_container {
  @apply mx-3 lg:mx-[2%] xl:mx-[3%] py-3;
}

.img_box img {
  transition: 0.1s ease-in-out;
}

.img_box:hover img {
  border-radius: 80px;
}


.rocket-icon {
  /* position: relative; */
  display: inline-block;
}

.rocket-icon img {
  /* position: absolute;
  right: 205px;
  top: -30px; */
  max-width: 70px;
}

@media only screen and (max-width: 700px) {
  .rocket-icon img {
      /* right: 108px; */
      /* top: auto; */
      max-width: 50px;
      /* bottom: -25px; */
  }
}

@media only screen and (max-width: 1400px) {
  .rocket-icon img {
      /* position: absolute;
      right: 205px;
      top: -30px; */
      max-width: 70px;
  }
}